import * as React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'
import { galleryContainer } from '../../styles/components/galleries.module.scss'
import '../../styles/components/swiper.css'
import { imgBox } from '../../styles/components/boxes.module.scss'
import { StaticImage } from 'gatsby-plugin-image'

const GalleryEightHour = () => {
  return (
    <div className={galleryContainer}>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className={imgBox}>
            <StaticImage
              alt="Friends jumping off a party boat in Tampa Bay, Florida."
              src="../../images/galleries/eight-hour-charter/friends-jumping-off-party-boat-in-tampa-bay.jpg"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={imgBox}>
            <StaticImage
              alt="Friends on a Tampa Bay, FL party boat."
              src="../../images/galleries/eight-hour-charter/friends-on-tampa-bay-florida-party-boat.jpg"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={imgBox}>
            <StaticImage
              alt="Guy in water with a hot girl near Beer Can Island in Tampa Bay, FL."
              src="../../images/galleries/eight-hour-charter/guy-in-water-with-hot-girl-near-beer-can-island.jpg"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={imgBox}>
            <StaticImage
              alt="Guys and girl on a party boat in Tampa, FL."
              src="../../images/galleries/eight-hour-charter/guys-and-girl-on-party-boat.jpg"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={imgBox}>
            <StaticImage
              alt="Water slide on Beer Can Island in Tampa Bay, FL."
              src="../../images/galleries/eight-hour-charter/water-slide-on-beer-can-island.jpg"
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default GalleryEightHour