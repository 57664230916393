import * as React from "react";
import { useState } from "react";
import LayoutService from "../../templates/LayoutService";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import BuyEightHourBtn from "../../components/buttons/BuyEightHourBtn";
import { Link } from "gatsby";
import "../../styles/components/titles.module.scss";
import "../../styles/components/header.module.scss";
import "../../styles/components/content.module.scss";
import "../../styles/components/contenttoggles.scss";
import { imgBox } from "../../styles/components/boxes.module.scss";
import {
  bannerBlock,
  bannerBlockHeadline,
  bannerBlockTagline,
  bannerBlockTitleSm,
  pageContentBlock,
  pageCalloutBlock,
  pageServiceDivider,
  serviceInfoBlock,
  serviceInfoBtns,
  pageContentMedia,
} from "../../styles/components/blocks.module.scss";
import { btn } from "../../styles/components/buttons.module.scss";
import { hideBlockSM } from "../../styles/components/helpers.module.scss";
import EightHourInfo from "../../components/blocks/EightHourInfo";
import { list3 } from "../../styles/components/lists.module.scss";
import GalleryEightHour from "../../components/galleries/GalleryEightHour";

const EightHourPage = () => {
  const [isActive, setActive] = useState(true);

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <LayoutService>
      <Helmet>
        <title>
          8-Hour Private Party Boat Charter | Tampa Bay Boating Adventures
        </title>
        <meta
          name="description"
          content="“It was worth every penny, and I would gladly do it again without hesitation.” Book a full-day with our party crew on an 8-hour party boat charter. Up to six guests."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <header className="container">
        <div className="row">
          <div className="col-xxs-12">
            <div className={bannerBlock}>
              <div className={imgBox}>
                <StaticImage
                  alt="Girls and guys with drinks on a party boat in Tampa Bay, FL."
                  src="../../images/banners/girls-and-guys-with-drinks.jpg"
                />
              </div>
              <div className={bannerBlockHeadline}>
                <h1 className={bannerBlockTagline}>
                  8-Hour Private Party Boat Charter in Tampa Bay
                </h1>
                <span className={bannerBlockTitleSm}>
                  See Tampa on <br className={hideBlockSM} />a Party Boat
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main className="container">
        <div className="row">
          <div className="col-xxs-12">
            <GalleryEightHour />
            <div className={serviceInfoBlock}>
              <h2>8-Hour Party Boat Package Summary</h2>
              <div
                className={serviceInfoBtns}
                style={{
                  display: "flex",
                  textAlign: "center",
                
                  justifyContent: "flex-start",
                }}
              >
                <BuyEightHourBtn />

                <Link
                  to="tel:8137428178"
                  className={`${btn}`}
                  style={{ marginRight: "10px", background: "#0DB0E7" }}
                >
                  Call (813) 742-8178
                </Link>

                {/* <a href="#description" className={btn} onClick={toggleClass}>{isActive ? "Hide Description" : "View Description"}</a> */}
              </div>

              {/* <a href="#description" className={btn} onClick={toggleClass}>{isActive ? "Hide Description" : "View Description"}</a> */}

              <EightHourInfo />
            </div>
            <div
              id="pageContentContainer"
              className={isActive ? "pageContentOpen" : "noClass"}
            >
              <div className="anchor" id="description"></div>
              <div className={pageContentBlock}>
                <hr className={pageServiceDivider} />
                <h2>
                  Party all day long on our 8-hour private party boat charter in
                  Tampa Bay
                </h2>
                <h3>
                  Our private party boat can take you to Beer Can Island, Tampa
                  Riverwalk, Armature Works, exclusive sandbars, or even some
                  private islands!
                </h3>
                <div className={pageContentMedia}>
                  <div className={imgBox}>
                    <StaticImage
                      alt="Group of partiers at a bar near Tampa, FL."
                      src="../../images/banners/group-of-partiers-at-bar.jpg"
                    />
                  </div>
                </div>
                <h2>
                  Island Hop Around Tampa Bay on Our 8-Hour Private Party Boat
                  Charter!
                </h2>
                <p>
                  The 8-hour workday has long been part of our culture–but what
                  about the 8-hour party day?! There’s no better way to spend a
                  day in Tampa Bay than partying on the water with your friends.
                  At Tampa Bay Boating Adventures, we offer an 8-hour private
                  party boat charter where you and your friends can spend the
                  day exploring the many popular attractions and private islands
                  in the area, such as Beer Can Island, the Tampa Riverwalk,
                  Sparkman Wharf, and much more!
                </p>
                <p>
                  Our trips are led by a friendly and experienced captain whose
                  number one priority is ensuring that you and your friends have
                  the time of your life. And to really get the party going, you
                  can bring along any kind of alcohol you want (…as long as
                  you’re 21 or older, of course!) We’ll provide fresh water,
                  non-alcoholic beverages, and an integrated, premium sound
                  system that will rock the boat.
                </p>
                <div className={serviceInfoBtns}
                style={{
                  display: "flex",
                  textAlign: "center",
                 
                  justifyContent: "flex-start",
                }}>
               
                  <BuyEightHourBtn />
                
                <Link
                  to="tel:8137428178"
                  className={`${btn}`}
                  style={{ marginRight: "10px", background: "#0DB0E7" }}
                >
                  Call (813) 742-8178
                </Link>
                </div>
                
                <hr />
                <h2>
                  Our 8-hour private party boat charter can take you to Beer Can
                  Island, The Riverwalk, exclusive sandbars, and many other
                  locations in the Tampa Bay Area.
                </h2>
                <p>
                  At Tampa Bay Boating Adventures, we go wherever you want! Our
                  friendly and experienced captain will take you and your
                  friends to your local destination of choice. Feel like
                  anchoring and partying at Beer Can Island? We’ll take you!
                  Would you rather have us dock at the Riverwalk so you can hit
                  the bars for a while? No problem! We’ll go wherever your
                  adventure takes you!
                </p>
                <h3>Some of our most popular destinations include:</h3>
                <ul className={list3}>
                  <li>Beer Can Island</li>
                  <li>Tampa Riverwalk</li>
                  <li>Downtown St. Pete</li>
                  <li>Armature Works</li>
                  <li>Sparkman Wharf</li>
                  <li>Private Islands</li>
                  <li>Sandbars</li>
                  <li>And much more!</li>
                </ul>
                <p>
                  In addition to our destinations, we can also drop the anchors
                  anywhere you want so you and your friends can take a dip in
                  our beautiful waters. We can even take you tubing if you would
                  like! Our primary goal is to make your trip unforgettable,
                  which is why we give you complete control of the itinerary!
                </p>
                <div className={serviceInfoBtns}
                style={{
                  display: "flex",
                  textAlign: "center",
                 
                  justifyContent: "flex-start",
                }}>
                  <BuyEightHourBtn />
                  <Link
                  to="tel:8137428178"
                  className={`${btn}`}
                  style={{ marginRight: "10px", background: "#0DB0E7" }}
                >
                  Call (813) 742-8178
                </Link>
                </div>
                <hr />
                <h2>What’s included in our 8-hour private party boat trips?</h2>
                <p>
                  At Tampa Bay Boating Adventures, in addition to taking you to
                  party with your friends on our private party boat, we’ll also
                  include a few things to make the day more enjoyable. We’ll
                  provide you with some snacks, non-alcoholic drinks, and
                  towels, so you won’t have to worry about bringing the basics.
                  However, you can bring anything you want such as floats, your
                  own snacks, music, or anything else you want to make your day
                  as enjoyable as possible.
                </p>
                <div className={pageCalloutBlock}>
                  <p>
                    We have curated playlists of every genre, but you can send
                    us your own playlist on Spotify, and we’ll play if for you!
                  </p>
                </div>
                <hr />
                <h2>Our 8-hour private party boat charter is BYOB!</h2>
                <p>
                  Is there anything better than drinking on the water with
                  friends? We don’t think so! While we don’t supply alcoholic
                  beverages, we want you to have a blast and we allow you to
                  bring any alcohol you want on the trip so you can turn it into
                  your own personal booze cruise. If you want to enjoy some hard
                  seltzers in the sun, shotgun some beers, or take some shots,
                  we fully support you! All we ask is that you drink responsibly
                  and be at least 21 years of age.
                </p>
                <hr />
                <h2>Schedule Your 8-hour Private Party Boat Charter Today!</h2>
                <p>
                  If you’re looking to make incredible memories with your
                  friends, it’s time to schedule an 8-hour private party boat
                  charter with Tampa Bay Boating Adventures! Whether you want to
                  hop around some party islands, go tubing, or hit the bars,
                  we’ve got you covered. Our trips are available to anyone in
                  the Tampa Bay area! Just fill out our{" "}
                  <Link to="/contact">submission form</Link> or{" "}
                  <a href="mailto:ahoy@tampabayboatingadventures.com">
                    send us an email
                  </a>{" "}
                  and we’ll get right back to you! We look forward to creating
                  an unforgettable adventure for you and your friends!
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </LayoutService>
  );
};

export default EightHourPage;
